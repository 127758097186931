/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1DuesRatesGetCollection } from '../fn/dues-rate/api-v-1-dues-rates-get-collection';
import { ApiV1DuesRatesGetCollection$Params } from '../fn/dues-rate/api-v-1-dues-rates-get-collection';
import { apiV1DuesRatesPost } from '../fn/dues-rate/api-v-1-dues-rates-post';
import { ApiV1DuesRatesPost$Params } from '../fn/dues-rate/api-v-1-dues-rates-post';
import { apiV1DuesRatesUuidDelete } from '../fn/dues-rate/api-v-1-dues-rates-uuid-delete';
import { ApiV1DuesRatesUuidDelete$Params } from '../fn/dues-rate/api-v-1-dues-rates-uuid-delete';
import { apiV1DuesRatesUuidGet } from '../fn/dues-rate/api-v-1-dues-rates-uuid-get';
import { ApiV1DuesRatesUuidGet$Params } from '../fn/dues-rate/api-v-1-dues-rates-uuid-get';
import { apiV1DuesRatesUuidPatch } from '../fn/dues-rate/api-v-1-dues-rates-uuid-patch';
import { ApiV1DuesRatesUuidPatch$Params } from '../fn/dues-rate/api-v-1-dues-rates-uuid-patch';
import { apiV1DuesRatesUuidPut } from '../fn/dues-rate/api-v-1-dues-rates-uuid-put';
import { ApiV1DuesRatesUuidPut$Params } from '../fn/dues-rate/api-v-1-dues-rates-uuid-put';
import { ApiDuesRate } from '../models/api-dues-rate';

@Injectable({ providedIn: 'root' })
export class ApiDuesRateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1DuesRatesGetCollection()` */
  static readonly ApiV1DuesRatesGetCollectionPath = '/v1/dues_rates';

  /**
   * Retrieves the collection of DuesRate resources.
   *
   * Retrieves the collection of DuesRate resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DuesRatesGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DuesRatesGetCollection$Response(params?: ApiV1DuesRatesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiDuesRate>>> {
    return apiV1DuesRatesGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of DuesRate resources.
   *
   * Retrieves the collection of DuesRate resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1DuesRatesGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DuesRatesGetCollection(params?: ApiV1DuesRatesGetCollection$Params, context?: HttpContext): Observable<Array<ApiDuesRate>> {
    return this.apiV1DuesRatesGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiDuesRate>>): Array<ApiDuesRate> => r.body)
    );
  }

  /** Path part for operation `apiV1DuesRatesPost()` */
  static readonly ApiV1DuesRatesPostPath = '/v1/dues_rates';

  /**
   * Creates a DuesRate resource.
   *
   * Creates a DuesRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DuesRatesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1DuesRatesPost$Response(params: ApiV1DuesRatesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiDuesRate>> {
    return apiV1DuesRatesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a DuesRate resource.
   *
   * Creates a DuesRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1DuesRatesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1DuesRatesPost(params: ApiV1DuesRatesPost$Params, context?: HttpContext): Observable<ApiDuesRate> {
    return this.apiV1DuesRatesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiDuesRate>): ApiDuesRate => r.body)
    );
  }

  /** Path part for operation `apiV1DuesRatesUuidGet()` */
  static readonly ApiV1DuesRatesUuidGetPath = '/v1/dues_rates/{uuid}';

  /**
   * Retrieves a DuesRate resource.
   *
   * Retrieves a DuesRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DuesRatesUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DuesRatesUuidGet$Response(params: ApiV1DuesRatesUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiDuesRate>> {
    return apiV1DuesRatesUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a DuesRate resource.
   *
   * Retrieves a DuesRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1DuesRatesUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DuesRatesUuidGet(params: ApiV1DuesRatesUuidGet$Params, context?: HttpContext): Observable<ApiDuesRate> {
    return this.apiV1DuesRatesUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiDuesRate>): ApiDuesRate => r.body)
    );
  }

  /** Path part for operation `apiV1DuesRatesUuidPut()` */
  static readonly ApiV1DuesRatesUuidPutPath = '/v1/dues_rates/{uuid}';

  /**
   * Replaces the DuesRate resource.
   *
   * Replaces the DuesRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DuesRatesUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1DuesRatesUuidPut$Response(params: ApiV1DuesRatesUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiDuesRate>> {
    return apiV1DuesRatesUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the DuesRate resource.
   *
   * Replaces the DuesRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1DuesRatesUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1DuesRatesUuidPut(params: ApiV1DuesRatesUuidPut$Params, context?: HttpContext): Observable<ApiDuesRate> {
    return this.apiV1DuesRatesUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiDuesRate>): ApiDuesRate => r.body)
    );
  }

  /** Path part for operation `apiV1DuesRatesUuidDelete()` */
  static readonly ApiV1DuesRatesUuidDeletePath = '/v1/dues_rates/{uuid}';

  /**
   * Removes the DuesRate resource.
   *
   * Removes the DuesRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DuesRatesUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DuesRatesUuidDelete$Response(params: ApiV1DuesRatesUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1DuesRatesUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the DuesRate resource.
   *
   * Removes the DuesRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1DuesRatesUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1DuesRatesUuidDelete(params: ApiV1DuesRatesUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1DuesRatesUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1DuesRatesUuidPatch()` */
  static readonly ApiV1DuesRatesUuidPatchPath = '/v1/dues_rates/{uuid}';

  /**
   * Updates the DuesRate resource.
   *
   * Updates the DuesRate resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1DuesRatesUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1DuesRatesUuidPatch$Response(params: ApiV1DuesRatesUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiDuesRate>> {
    return apiV1DuesRatesUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the DuesRate resource.
   *
   * Updates the DuesRate resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1DuesRatesUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1DuesRatesUuidPatch(params: ApiV1DuesRatesUuidPatch$Params, context?: HttpContext): Observable<ApiDuesRate> {
    return this.apiV1DuesRatesUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiDuesRate>): ApiDuesRate => r.body)
    );
  }

}
