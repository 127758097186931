/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1WaitingListsGetCollection } from '../fn/waiting-list/api-v-1-waiting-lists-get-collection';
import { ApiV1WaitingListsGetCollection$Params } from '../fn/waiting-list/api-v-1-waiting-lists-get-collection';
import { apiV1WaitingListsPost } from '../fn/waiting-list/api-v-1-waiting-lists-post';
import { ApiV1WaitingListsPost$Params } from '../fn/waiting-list/api-v-1-waiting-lists-post';
import { apiV1WaitingListsUuidDelete } from '../fn/waiting-list/api-v-1-waiting-lists-uuid-delete';
import { ApiV1WaitingListsUuidDelete$Params } from '../fn/waiting-list/api-v-1-waiting-lists-uuid-delete';
import { apiV1WaitingListsUuidGet } from '../fn/waiting-list/api-v-1-waiting-lists-uuid-get';
import { ApiV1WaitingListsUuidGet$Params } from '../fn/waiting-list/api-v-1-waiting-lists-uuid-get';
import { apiV1WaitingListsUuidPatch } from '../fn/waiting-list/api-v-1-waiting-lists-uuid-patch';
import { ApiV1WaitingListsUuidPatch$Params } from '../fn/waiting-list/api-v-1-waiting-lists-uuid-patch';
import { apiV1WaitingListsUuidPut } from '../fn/waiting-list/api-v-1-waiting-lists-uuid-put';
import { ApiV1WaitingListsUuidPut$Params } from '../fn/waiting-list/api-v-1-waiting-lists-uuid-put';
import { ApiWaitingList } from '../models/api-waiting-list';

@Injectable({ providedIn: 'root' })
export class ApiWaitingListService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1WaitingListsGetCollection()` */
  static readonly ApiV1WaitingListsGetCollectionPath = '/v1/waiting_lists';

  /**
   * Retrieves the collection of WaitingList resources.
   *
   * Retrieves the collection of WaitingList resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1WaitingListsGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1WaitingListsGetCollection$Response(params?: ApiV1WaitingListsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiWaitingList>>> {
    return apiV1WaitingListsGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of WaitingList resources.
   *
   * Retrieves the collection of WaitingList resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1WaitingListsGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1WaitingListsGetCollection(params?: ApiV1WaitingListsGetCollection$Params, context?: HttpContext): Observable<Array<ApiWaitingList>> {
    return this.apiV1WaitingListsGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiWaitingList>>): Array<ApiWaitingList> => r.body)
    );
  }

  /** Path part for operation `apiV1WaitingListsPost()` */
  static readonly ApiV1WaitingListsPostPath = '/v1/waiting_lists';

  /**
   * Creates a WaitingList resource.
   *
   * Creates a WaitingList resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1WaitingListsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1WaitingListsPost$Response(params: ApiV1WaitingListsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiWaitingList>> {
    return apiV1WaitingListsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a WaitingList resource.
   *
   * Creates a WaitingList resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1WaitingListsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1WaitingListsPost(params: ApiV1WaitingListsPost$Params, context?: HttpContext): Observable<ApiWaitingList> {
    return this.apiV1WaitingListsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiWaitingList>): ApiWaitingList => r.body)
    );
  }

  /** Path part for operation `apiV1WaitingListsUuidGet()` */
  static readonly ApiV1WaitingListsUuidGetPath = '/v1/waiting_lists/{uuid}';

  /**
   * Retrieves a WaitingList resource.
   *
   * Retrieves a WaitingList resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1WaitingListsUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1WaitingListsUuidGet$Response(params: ApiV1WaitingListsUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiWaitingList>> {
    return apiV1WaitingListsUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a WaitingList resource.
   *
   * Retrieves a WaitingList resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1WaitingListsUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1WaitingListsUuidGet(params: ApiV1WaitingListsUuidGet$Params, context?: HttpContext): Observable<ApiWaitingList> {
    return this.apiV1WaitingListsUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiWaitingList>): ApiWaitingList => r.body)
    );
  }

  /** Path part for operation `apiV1WaitingListsUuidPut()` */
  static readonly ApiV1WaitingListsUuidPutPath = '/v1/waiting_lists/{uuid}';

  /**
   * Replaces the WaitingList resource.
   *
   * Replaces the WaitingList resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1WaitingListsUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1WaitingListsUuidPut$Response(params: ApiV1WaitingListsUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiWaitingList>> {
    return apiV1WaitingListsUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the WaitingList resource.
   *
   * Replaces the WaitingList resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1WaitingListsUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1WaitingListsUuidPut(params: ApiV1WaitingListsUuidPut$Params, context?: HttpContext): Observable<ApiWaitingList> {
    return this.apiV1WaitingListsUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiWaitingList>): ApiWaitingList => r.body)
    );
  }

  /** Path part for operation `apiV1WaitingListsUuidDelete()` */
  static readonly ApiV1WaitingListsUuidDeletePath = '/v1/waiting_lists/{uuid}';

  /**
   * Removes the WaitingList resource.
   *
   * Removes the WaitingList resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1WaitingListsUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1WaitingListsUuidDelete$Response(params: ApiV1WaitingListsUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1WaitingListsUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the WaitingList resource.
   *
   * Removes the WaitingList resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1WaitingListsUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1WaitingListsUuidDelete(params: ApiV1WaitingListsUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1WaitingListsUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1WaitingListsUuidPatch()` */
  static readonly ApiV1WaitingListsUuidPatchPath = '/v1/waiting_lists/{uuid}';

  /**
   * Updates the WaitingList resource.
   *
   * Updates the WaitingList resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1WaitingListsUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1WaitingListsUuidPatch$Response(params: ApiV1WaitingListsUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiWaitingList>> {
    return apiV1WaitingListsUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the WaitingList resource.
   *
   * Updates the WaitingList resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1WaitingListsUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1WaitingListsUuidPatch(params: ApiV1WaitingListsUuidPatch$Params, context?: HttpContext): Observable<ApiWaitingList> {
    return this.apiV1WaitingListsUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiWaitingList>): ApiWaitingList => r.body)
    );
  }

}
