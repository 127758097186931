/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiJoinPeriod } from '../../models/api-join-period';

export interface ApiV1JoinPeriodsUuidPut$Params {

/**
 * JoinPeriod identifier
 */
  uuid: string;

/**
 * Transaction ID for the business request
 */
  'X-Transaction-Id'?: string;
  
    /**
     * The updated JoinPeriod resource
     */
    body: ApiJoinPeriod
}

export function apiV1JoinPeriodsUuidPut(http: HttpClient, rootUrl: string, params: ApiV1JoinPeriodsUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinPeriod>> {
  const rb = new RequestBuilder(rootUrl, apiV1JoinPeriodsUuidPut.PATH, 'put');
  if (params) {
    rb.path('uuid', params.uuid, {"style":"simple","explode":false});
    rb.header('X-Transaction-Id', params['X-Transaction-Id'], {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ApiJoinPeriod>;
    })
  );
}

apiV1JoinPeriodsUuidPut.PATH = '/v1/join_periods/{uuid}';
