/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiJoinRule } from '../../models/api-join-rule';

export interface ApiV1JoinRulesPost$Params {

/**
 * Transaction ID for the business request
 */
  'X-Transaction-Id'?: string;
  
    /**
     * The new JoinRule resource
     */
    body: ApiJoinRule
}

export function apiV1JoinRulesPost(http: HttpClient, rootUrl: string, params: ApiV1JoinRulesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinRule>> {
  const rb = new RequestBuilder(rootUrl, apiV1JoinRulesPost.PATH, 'post');
  if (params) {
    rb.header('X-Transaction-Id', params['X-Transaction-Id'], {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ApiJoinRule>;
    })
  );
}

apiV1JoinRulesPost.PATH = '/v1/join_rules';
