<div class="title1">
    <span class="title-blue">Notre Club </span><span class="title-red">b2m futsal</span>
</div>
<section>
    <div class="container">
        <div class="intro-text justify">
            Fondé en 2003 avec un collectif de 21 licenciés, le club n’a cessé d’évoluer au court de son histoire,
            pour devenir une association qui compte sur les Bords de Marne, mais aussi comme <b>un acteur majeur et
                reconnu dans le paysage</b> du Futsal en Île-de-France et au niveau national.
            <br><br>
            Le club, <strong>fondateur et gestionnaire du collectif jeunes Île-de-France</strong>, se réjouit
            d’être l'une des académies françaises les plus prolifiques en nombre de licenciés, le créateur des
            <strong>premières sections femikids et femiteens du pays</strong> et l'un des clubs ayant mis en place
            de nouveaux formats autour de sa discipline, tels que Baby Futsal et Futsal en Marchant.
            <br><br>
            Avec une équipe dirigeante, mixte et impliquée, <strong>B2M Futsal s’inscrit dans une démarche
                sportive, éducative et collective majeure</strong>, au coeur de son environnement géographique,
            en proposant de nombreux projets qui dépassent le simple cadre du Futsal, avec une volonté farouche
            d'innover et de réinventer le club.
            <br><br>
            <strong>B2M Futsal a su tirer partie des nombreuses compétences professionnelles de ses bénévoles, pour
                développer des pôles au service du sport.</strong> Grâce à <strong>B2M Project, B2M Partners</strong> et
            <strong>B2M TV</strong>, nous sommes structurés pour développer des projets inédits et trouver des
            partenaires, afin de nous permettre de grandir. Ces pôles sont des relais médias incontournables qui mettent
            en valeur chaque action et chaque opération du club.
        </div>

        <div class="visu_club mx-auto">
            <img src="assets/images/visu_club_001.jpg" class="img-fluid">
        </div>

        <div class="title1">Nos valeurs</div>

        <div class="intro-text text-center">
            <div class="title-red"><strong>Le club s'appuie sur des valeurs fortes :</strong></div>
            <strong>Respect - Formation - Partage - Esprit collectif - Fair-play - Progression - Transmission -
                Plaisir</strong>
            <br>
            S’épanouir et grandir grâce à la pratique du Futsal reste un des plus beaux défis du club.
        </div>

        <div class="btn-more btn-blue mx-auto"><a [routerLink]="['/internal-rules']">REGLEMENT INTERIEUR</a></div>

        <hr class="violet-hr">

        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <h3 class="card-title">1</h3>
                  <p class="card-text">1er club de France en 2023/2024</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <h3 class="card-title">560+</h3>
                  <p class="card-text">+ de 560 adhérents</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <h3 class="card-title">1</h3>
                  <p class="card-text">Première Filière Féminine de France</p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card text-center">
                <div class="card-body">
                  <h3 class="card-title">8</h3>
                  <p class="card-text">Gymnases</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <hr class="violet-hr">

        <div class="row">

            <!--<div wmAnimate="bounceInDown" speed="slower" aos="1">-->
            <div class="container" >
                <div class="title1">le palmarès du club</div>

                <div class="d-flex flex-wrap">
                    <div class="col-xs-12 col-sm-7">
                        <img src="assets/images/visu_club_002.jpg" class="img-fluid">
                    </div>

                    <div class="col-xs-12 col-sm-5 palmares">
                        <div class="title2">Séniors</div>
                        <ul>
                            <li>Coupe du Val-de-Marne 2009, 2010, 2012, 2014, 2015 et 2019</li>
                            <li>Champion de Promotion d'Honneur 2010</li>
                            <li>Consolante du Mondial Futsal 2011</li>
                        </ul>

                        <div class="title2">U18</div>
                        <ul>
                            <li>Tournoi de Chatou 2019</li>
                        </ul>
                        <div class="title2">U17</div>
                        <ul>
                            <li>Critérium Régional 2010</li>
                        </ul>
                        <div class="title2">U15</div>
                        <ul>
                            <li>Tournoi du Sporting Paris 2019</li>
                        </ul>
                        <div class="title2">U13</div>
                        <ul>
                            <li>Tournoi La Bassée 2013 et 2018</li>
                            <li>Champion du collectif régional 2015 et 2016</li>
                            <li>Tournoi de l'US Créteil 2019</li>
                        </ul>
                        <div class="title2">U12</div>
                        <ul>
                          <li>Swany Futsal Cup 2024 et 2025</li>
                        </ul>
                        <div class="title2">U11</div>
                        <ul>
                            <li>Champion du collectif régional 2014 et 2016</li>
                            <li>Tournoi Montesilvano 2017</li>
                            <li>Tournoi de Mouscron 2017</li>
                            <li>Fenice Cup (Tournoi de Venise) 2020</li>
                            <li>Sala Kid'z Cup (Tournoi de Chambord) 2020</li>
                            <li>Funchal Futsal Cup 2023</li>
                            <li>Nesles Futsal Cup (Tournoi de Champs FFC) 2025</li>
                        </ul>
                        <div class="title2">U10</div>
                        <ul>
                            <li>Costa Daurada 2024</li>
                            <li>Swany Futsal Cup 2024</li>
                            <li>Tournoi du Sporting Paris 2022</li>
                        </ul>
                        <div class="title2">U9</div>
                        <ul>
                            <li>Tournoi de La Bassée 2015</li>
                            <li>Tournoi de Garges 2023</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr class="violet-hr">

    <b2m-organization-chart [orgArray]="boardArray" [anchorId]="'codir'" [orgTitle]="boardTitle"></b2m-organization-chart>

    <hr class="violet-hr">
</section>

