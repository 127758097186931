/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1RolesGetCollection } from '../fn/role/api-v-1-roles-get-collection';
import { ApiV1RolesGetCollection$Params } from '../fn/role/api-v-1-roles-get-collection';
import { apiV1RolesPost } from '../fn/role/api-v-1-roles-post';
import { ApiV1RolesPost$Params } from '../fn/role/api-v-1-roles-post';
import { apiV1RolesUuidDelete } from '../fn/role/api-v-1-roles-uuid-delete';
import { ApiV1RolesUuidDelete$Params } from '../fn/role/api-v-1-roles-uuid-delete';
import { apiV1RolesUuidGet } from '../fn/role/api-v-1-roles-uuid-get';
import { ApiV1RolesUuidGet$Params } from '../fn/role/api-v-1-roles-uuid-get';
import { apiV1RolesUuidPatch } from '../fn/role/api-v-1-roles-uuid-patch';
import { ApiV1RolesUuidPatch$Params } from '../fn/role/api-v-1-roles-uuid-patch';
import { apiV1RolesUuidPut } from '../fn/role/api-v-1-roles-uuid-put';
import { ApiV1RolesUuidPut$Params } from '../fn/role/api-v-1-roles-uuid-put';
import { ApiRole } from '../models/api-role';

@Injectable({ providedIn: 'root' })
export class ApiRoleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1RolesGetCollection()` */
  static readonly ApiV1RolesGetCollectionPath = '/v1/roles';

  /**
   * Retrieves the collection of Role resources.
   *
   * Retrieves the collection of Role resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RolesGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RolesGetCollection$Response(params?: ApiV1RolesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiRole>>> {
    return apiV1RolesGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Role resources.
   *
   * Retrieves the collection of Role resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1RolesGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RolesGetCollection(params?: ApiV1RolesGetCollection$Params, context?: HttpContext): Observable<Array<ApiRole>> {
    return this.apiV1RolesGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiRole>>): Array<ApiRole> => r.body)
    );
  }

  /** Path part for operation `apiV1RolesPost()` */
  static readonly ApiV1RolesPostPath = '/v1/roles';

  /**
   * Creates a Role resource.
   *
   * Creates a Role resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RolesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RolesPost$Response(params: ApiV1RolesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiRole>> {
    return apiV1RolesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Role resource.
   *
   * Creates a Role resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1RolesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RolesPost(params: ApiV1RolesPost$Params, context?: HttpContext): Observable<ApiRole> {
    return this.apiV1RolesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiRole>): ApiRole => r.body)
    );
  }

  /** Path part for operation `apiV1RolesUuidGet()` */
  static readonly ApiV1RolesUuidGetPath = '/v1/roles/{uuid}';

  /**
   * Retrieves a Role resource.
   *
   * Retrieves a Role resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RolesUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RolesUuidGet$Response(params: ApiV1RolesUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiRole>> {
    return apiV1RolesUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Role resource.
   *
   * Retrieves a Role resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1RolesUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RolesUuidGet(params: ApiV1RolesUuidGet$Params, context?: HttpContext): Observable<ApiRole> {
    return this.apiV1RolesUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiRole>): ApiRole => r.body)
    );
  }

  /** Path part for operation `apiV1RolesUuidPut()` */
  static readonly ApiV1RolesUuidPutPath = '/v1/roles/{uuid}';

  /**
   * Replaces the Role resource.
   *
   * Replaces the Role resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RolesUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RolesUuidPut$Response(params: ApiV1RolesUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiRole>> {
    return apiV1RolesUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Role resource.
   *
   * Replaces the Role resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1RolesUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1RolesUuidPut(params: ApiV1RolesUuidPut$Params, context?: HttpContext): Observable<ApiRole> {
    return this.apiV1RolesUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiRole>): ApiRole => r.body)
    );
  }

  /** Path part for operation `apiV1RolesUuidDelete()` */
  static readonly ApiV1RolesUuidDeletePath = '/v1/roles/{uuid}';

  /**
   * Removes the Role resource.
   *
   * Removes the Role resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RolesUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RolesUuidDelete$Response(params: ApiV1RolesUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1RolesUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Role resource.
   *
   * Removes the Role resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1RolesUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1RolesUuidDelete(params: ApiV1RolesUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1RolesUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1RolesUuidPatch()` */
  static readonly ApiV1RolesUuidPatchPath = '/v1/roles/{uuid}';

  /**
   * Updates the Role resource.
   *
   * Updates the Role resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1RolesUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1RolesUuidPatch$Response(params: ApiV1RolesUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiRole>> {
    return apiV1RolesUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Role resource.
   *
   * Updates the Role resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1RolesUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1RolesUuidPatch(params: ApiV1RolesUuidPatch$Params, context?: HttpContext): Observable<ApiRole> {
    return this.apiV1RolesUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiRole>): ApiRole => r.body)
    );
  }

}
