/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1JoinRulesGetCollection } from '../fn/join-rule/api-v-1-join-rules-get-collection';
import { ApiV1JoinRulesGetCollection$Params } from '../fn/join-rule/api-v-1-join-rules-get-collection';
import { apiV1JoinRulesPost } from '../fn/join-rule/api-v-1-join-rules-post';
import { ApiV1JoinRulesPost$Params } from '../fn/join-rule/api-v-1-join-rules-post';
import { apiV1JoinRulesUuidDelete } from '../fn/join-rule/api-v-1-join-rules-uuid-delete';
import { ApiV1JoinRulesUuidDelete$Params } from '../fn/join-rule/api-v-1-join-rules-uuid-delete';
import { apiV1JoinRulesUuidGet } from '../fn/join-rule/api-v-1-join-rules-uuid-get';
import { ApiV1JoinRulesUuidGet$Params } from '../fn/join-rule/api-v-1-join-rules-uuid-get';
import { apiV1JoinRulesUuidPatch } from '../fn/join-rule/api-v-1-join-rules-uuid-patch';
import { ApiV1JoinRulesUuidPatch$Params } from '../fn/join-rule/api-v-1-join-rules-uuid-patch';
import { apiV1JoinRulesUuidPut } from '../fn/join-rule/api-v-1-join-rules-uuid-put';
import { ApiV1JoinRulesUuidPut$Params } from '../fn/join-rule/api-v-1-join-rules-uuid-put';
import { ApiJoinRule } from '../models/api-join-rule';

@Injectable({ providedIn: 'root' })
export class ApiJoinRuleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1JoinRulesGetCollection()` */
  static readonly ApiV1JoinRulesGetCollectionPath = '/v1/join_rules';

  /**
   * Retrieves the collection of JoinRule resources.
   *
   * Retrieves the collection of JoinRule resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinRulesGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinRulesGetCollection$Response(params?: ApiV1JoinRulesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiJoinRule>>> {
    return apiV1JoinRulesGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of JoinRule resources.
   *
   * Retrieves the collection of JoinRule resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinRulesGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinRulesGetCollection(params?: ApiV1JoinRulesGetCollection$Params, context?: HttpContext): Observable<Array<ApiJoinRule>> {
    return this.apiV1JoinRulesGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiJoinRule>>): Array<ApiJoinRule> => r.body)
    );
  }

  /** Path part for operation `apiV1JoinRulesPost()` */
  static readonly ApiV1JoinRulesPostPath = '/v1/join_rules';

  /**
   * Creates a JoinRule resource.
   *
   * Creates a JoinRule resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinRulesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1JoinRulesPost$Response(params: ApiV1JoinRulesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinRule>> {
    return apiV1JoinRulesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a JoinRule resource.
   *
   * Creates a JoinRule resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinRulesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1JoinRulesPost(params: ApiV1JoinRulesPost$Params, context?: HttpContext): Observable<ApiJoinRule> {
    return this.apiV1JoinRulesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiJoinRule>): ApiJoinRule => r.body)
    );
  }

  /** Path part for operation `apiV1JoinRulesUuidGet()` */
  static readonly ApiV1JoinRulesUuidGetPath = '/v1/join_rules/{uuid}';

  /**
   * Retrieves a JoinRule resource.
   *
   * Retrieves a JoinRule resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinRulesUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinRulesUuidGet$Response(params: ApiV1JoinRulesUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinRule>> {
    return apiV1JoinRulesUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a JoinRule resource.
   *
   * Retrieves a JoinRule resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinRulesUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinRulesUuidGet(params: ApiV1JoinRulesUuidGet$Params, context?: HttpContext): Observable<ApiJoinRule> {
    return this.apiV1JoinRulesUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiJoinRule>): ApiJoinRule => r.body)
    );
  }

  /** Path part for operation `apiV1JoinRulesUuidPut()` */
  static readonly ApiV1JoinRulesUuidPutPath = '/v1/join_rules/{uuid}';

  /**
   * Replaces the JoinRule resource.
   *
   * Replaces the JoinRule resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinRulesUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1JoinRulesUuidPut$Response(params: ApiV1JoinRulesUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinRule>> {
    return apiV1JoinRulesUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the JoinRule resource.
   *
   * Replaces the JoinRule resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinRulesUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1JoinRulesUuidPut(params: ApiV1JoinRulesUuidPut$Params, context?: HttpContext): Observable<ApiJoinRule> {
    return this.apiV1JoinRulesUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiJoinRule>): ApiJoinRule => r.body)
    );
  }

  /** Path part for operation `apiV1JoinRulesUuidDelete()` */
  static readonly ApiV1JoinRulesUuidDeletePath = '/v1/join_rules/{uuid}';

  /**
   * Removes the JoinRule resource.
   *
   * Removes the JoinRule resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinRulesUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinRulesUuidDelete$Response(params: ApiV1JoinRulesUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1JoinRulesUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the JoinRule resource.
   *
   * Removes the JoinRule resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinRulesUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1JoinRulesUuidDelete(params: ApiV1JoinRulesUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1JoinRulesUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1JoinRulesUuidPatch()` */
  static readonly ApiV1JoinRulesUuidPatchPath = '/v1/join_rules/{uuid}';

  /**
   * Updates the JoinRule resource.
   *
   * Updates the JoinRule resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1JoinRulesUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1JoinRulesUuidPatch$Response(params: ApiV1JoinRulesUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiJoinRule>> {
    return apiV1JoinRulesUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the JoinRule resource.
   *
   * Updates the JoinRule resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1JoinRulesUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1JoinRulesUuidPatch(params: ApiV1JoinRulesUuidPatch$Params, context?: HttpContext): Observable<ApiJoinRule> {
    return this.apiV1JoinRulesUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiJoinRule>): ApiJoinRule => r.body)
    );
  }

}
