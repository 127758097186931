/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { ApiExternalData } from '../models/api-external-data';
export class ApiMember {
  address?: (string | null);
  birthCountry?: (string | null);
  birthDate?: string;
  birthName?: (string | null);
  birthTown?: (string | null);
  changingClub?: (boolean | null);
  consents?: Array<string>;
  createdAt?: string;
  email?: (string | null);
  externalDatas?: Array<ApiExternalData>;
  firstName?: string;
  gender?: string;
  household?: (string | null);
  id?: number;
  lastName?: string;
  licenseNumber?: (string | null);
  memberships?: Array<string>;
  nationality?: (string | null);
  phoneMobile?: (string | null);
  shoeSize?: (string | null);
  size?: (string | null);
  status?: 'active' | 'pending' | 'suspended' | 'inactive';
  town?: (string | null);
  updatedAt?: string;
  uuid?: string;
  waitingLists?: Array<string>;
  zip?: (string | null);
}

