/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1MembershipsGetCollection } from '../fn/membership/api-v-1-memberships-get-collection';
import { ApiV1MembershipsGetCollection$Params } from '../fn/membership/api-v-1-memberships-get-collection';
import { apiV1MembershipsPost } from '../fn/membership/api-v-1-memberships-post';
import { ApiV1MembershipsPost$Params } from '../fn/membership/api-v-1-memberships-post';
import { apiV1MembershipsUuidDelete } from '../fn/membership/api-v-1-memberships-uuid-delete';
import { ApiV1MembershipsUuidDelete$Params } from '../fn/membership/api-v-1-memberships-uuid-delete';
import { apiV1MembershipsUuidGet } from '../fn/membership/api-v-1-memberships-uuid-get';
import { ApiV1MembershipsUuidGet$Params } from '../fn/membership/api-v-1-memberships-uuid-get';
import { apiV1MembershipsUuidPatch } from '../fn/membership/api-v-1-memberships-uuid-patch';
import { ApiV1MembershipsUuidPatch$Params } from '../fn/membership/api-v-1-memberships-uuid-patch';
import { apiV1MembershipsUuidPut } from '../fn/membership/api-v-1-memberships-uuid-put';
import { ApiV1MembershipsUuidPut$Params } from '../fn/membership/api-v-1-memberships-uuid-put';
import { ApiMembership } from '../models/api-membership';

@Injectable({ providedIn: 'root' })
export class ApiMembershipService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1MembershipsGetCollection()` */
  static readonly ApiV1MembershipsGetCollectionPath = '/v1/memberships';

  /**
   * Retrieves the collection of Membership resources.
   *
   * Retrieves the collection of Membership resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembershipsGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembershipsGetCollection$Response(params?: ApiV1MembershipsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiMembership>>> {
    return apiV1MembershipsGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Membership resources.
   *
   * Retrieves the collection of Membership resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembershipsGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembershipsGetCollection(params?: ApiV1MembershipsGetCollection$Params, context?: HttpContext): Observable<Array<ApiMembership>> {
    return this.apiV1MembershipsGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiMembership>>): Array<ApiMembership> => r.body)
    );
  }

  /** Path part for operation `apiV1MembershipsPost()` */
  static readonly ApiV1MembershipsPostPath = '/v1/memberships';

  /**
   * Creates a Membership resource.
   *
   * Creates a Membership resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembershipsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MembershipsPost$Response(params: ApiV1MembershipsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiMembership>> {
    return apiV1MembershipsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Membership resource.
   *
   * Creates a Membership resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembershipsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MembershipsPost(params: ApiV1MembershipsPost$Params, context?: HttpContext): Observable<ApiMembership> {
    return this.apiV1MembershipsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiMembership>): ApiMembership => r.body)
    );
  }

  /** Path part for operation `apiV1MembershipsUuidGet()` */
  static readonly ApiV1MembershipsUuidGetPath = '/v1/memberships/{uuid}';

  /**
   * Retrieves a Membership resource.
   *
   * Retrieves a Membership resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembershipsUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembershipsUuidGet$Response(params: ApiV1MembershipsUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiMembership>> {
    return apiV1MembershipsUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Membership resource.
   *
   * Retrieves a Membership resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembershipsUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembershipsUuidGet(params: ApiV1MembershipsUuidGet$Params, context?: HttpContext): Observable<ApiMembership> {
    return this.apiV1MembershipsUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiMembership>): ApiMembership => r.body)
    );
  }

  /** Path part for operation `apiV1MembershipsUuidPut()` */
  static readonly ApiV1MembershipsUuidPutPath = '/v1/memberships/{uuid}';

  /**
   * Replaces the Membership resource.
   *
   * Replaces the Membership resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembershipsUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MembershipsUuidPut$Response(params: ApiV1MembershipsUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiMembership>> {
    return apiV1MembershipsUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Membership resource.
   *
   * Replaces the Membership resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembershipsUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1MembershipsUuidPut(params: ApiV1MembershipsUuidPut$Params, context?: HttpContext): Observable<ApiMembership> {
    return this.apiV1MembershipsUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiMembership>): ApiMembership => r.body)
    );
  }

  /** Path part for operation `apiV1MembershipsUuidDelete()` */
  static readonly ApiV1MembershipsUuidDeletePath = '/v1/memberships/{uuid}';

  /**
   * Removes the Membership resource.
   *
   * Removes the Membership resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembershipsUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembershipsUuidDelete$Response(params: ApiV1MembershipsUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1MembershipsUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Membership resource.
   *
   * Removes the Membership resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembershipsUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1MembershipsUuidDelete(params: ApiV1MembershipsUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1MembershipsUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1MembershipsUuidPatch()` */
  static readonly ApiV1MembershipsUuidPatchPath = '/v1/memberships/{uuid}';

  /**
   * Updates the Membership resource.
   *
   * Updates the Membership resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1MembershipsUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1MembershipsUuidPatch$Response(params: ApiV1MembershipsUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiMembership>> {
    return apiV1MembershipsUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Membership resource.
   *
   * Updates the Membership resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1MembershipsUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1MembershipsUuidPatch(params: ApiV1MembershipsUuidPatch$Params, context?: HttpContext): Observable<ApiMembership> {
    return this.apiV1MembershipsUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiMembership>): ApiMembership => r.body)
    );
  }

}
