/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiMember } from '../../models/api-member';

export interface ApiV1MembersGetCollection$Params {

/**
 * The collection page number
 */
  page?: number;
  firstName?: string;
  lastName?: string;
  gender?: string;
  'gender[]'?: Array<string>;
  birthDate?: string;
  'birthDate[]'?: string;

/**
 * Transaction ID for the business request
 */
  'X-Transaction-Id'?: string;
}

export function apiV1MembersGetCollection(http: HttpClient, rootUrl: string, params?: ApiV1MembersGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiMember>>> {
  const rb = new RequestBuilder(rootUrl, apiV1MembersGetCollection.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('firstName', params.firstName, {"style":"form","explode":false});
    rb.query('lastName', params.lastName, {"style":"form","explode":false});
    rb.query('gender', params.gender, {"style":"form","explode":false});
    rb.query('gender[]', params['gender[]'], {"style":"form","explode":true});
    rb.query('birthDate', params.birthDate, {"style":"form","explode":false});
    rb.query('birthDate[]', params['birthDate[]'], {"style":"form","explode":true});
    rb.header('X-Transaction-Id', params['X-Transaction-Id'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ApiMember>>;
    })
  );
}

apiV1MembersGetCollection.PATH = '/v1/members';
