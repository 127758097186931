/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiConsentPurpose } from '../../models/api-consent-purpose';

export interface ApiV1ConsentPurposesGetCollection$Params {

/**
 * The collection page number
 */
  page?: number;
  'order[displayOrder]'?: 'asc' | 'desc';

/**
 * Transaction ID for the business request
 */
  'X-Transaction-Id'?: string;
}

export function apiV1ConsentPurposesGetCollection(http: HttpClient, rootUrl: string, params?: ApiV1ConsentPurposesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiConsentPurpose>>> {
  const rb = new RequestBuilder(rootUrl, apiV1ConsentPurposesGetCollection.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('order[displayOrder]', params['order[displayOrder]'], {"style":"form","explode":false});
    rb.header('X-Transaction-Id', params['X-Transaction-Id'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ApiConsentPurpose>>;
    })
  );
}

apiV1ConsentPurposesGetCollection.PATH = '/v1/consent_purposes';
