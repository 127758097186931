/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiWaitingList {
  category?: string;
  createdAt?: string;
  id?: number;
  member?: string;
  updatedAt?: string;
  uuid?: string;
}

