/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiJsonld {
  '@context'?: (string | {
'@vocab': string;
'hydra': 'http://www.w3.org/ns/hydra/core#';
[key: string]: any;
});
  '@id'?: string;
  '@type'?: string;
  code?: string;
  createdAt?: string;
  description?: (string | null);
  id?: number;
  previousCodes?: any;
  updatedAt?: string;
  uuid?: string;
}

