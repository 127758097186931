/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiMembership {
  attendanceRate?: (number | null);
  category?: string;
  createdAt?: string;
  id?: number;
  member?: string;

  /**
   * Membership fee payment status (UNPAID, PAID, PARTIALLY_PAID, REFUNDED, EXEMPT).
   */
  paymentStatus?: 'PAID' | 'UNPAID' | 'PARTIALLY_PAID' | 'REFUNDED' | 'EXEMPT';
  role?: string;
  updatedAt?: string;
  uuid?: string;
}

