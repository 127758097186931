/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiJoinRule {
  code?: string;
  createdAt?: string;
  description?: (string | null);
  id?: number;
  previousCodes?: any;
  updatedAt?: string;
  uuid?: string;
}

