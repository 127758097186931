/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiCredential {
  id?: number;
  password?: (string | null);
  provider?: string;
  token?: (string | null);
  url?: (string | null);
  username?: (string | null);
}

