/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { ApiExternalData } from '../models/api-external-data';
export class ApiCountry {
  active?: boolean;
  code?: (string | null);
  codeIso?: (string | null);
  code_iso?: (string | null);
  createdAt?: string;
  eu?: boolean;
  externalDatas?: Array<ApiExternalData>;
  favorite?: boolean;
  id?: number;
  label?: (string | null);
  updatedAt?: string;
  uuid?: string;
}

