/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1ConsentPurposesGetCollection } from '../fn/consent-purpose/api-v-1-consent-purposes-get-collection';
import { ApiV1ConsentPurposesGetCollection$Params } from '../fn/consent-purpose/api-v-1-consent-purposes-get-collection';
import { apiV1ConsentPurposesPost } from '../fn/consent-purpose/api-v-1-consent-purposes-post';
import { ApiV1ConsentPurposesPost$Params } from '../fn/consent-purpose/api-v-1-consent-purposes-post';
import { apiV1ConsentPurposesUuidDelete } from '../fn/consent-purpose/api-v-1-consent-purposes-uuid-delete';
import { ApiV1ConsentPurposesUuidDelete$Params } from '../fn/consent-purpose/api-v-1-consent-purposes-uuid-delete';
import { apiV1ConsentPurposesUuidGet } from '../fn/consent-purpose/api-v-1-consent-purposes-uuid-get';
import { ApiV1ConsentPurposesUuidGet$Params } from '../fn/consent-purpose/api-v-1-consent-purposes-uuid-get';
import { apiV1ConsentPurposesUuidPatch } from '../fn/consent-purpose/api-v-1-consent-purposes-uuid-patch';
import { ApiV1ConsentPurposesUuidPatch$Params } from '../fn/consent-purpose/api-v-1-consent-purposes-uuid-patch';
import { apiV1ConsentPurposesUuidPut } from '../fn/consent-purpose/api-v-1-consent-purposes-uuid-put';
import { ApiV1ConsentPurposesUuidPut$Params } from '../fn/consent-purpose/api-v-1-consent-purposes-uuid-put';
import { ApiConsentPurpose } from '../models/api-consent-purpose';

@Injectable({ providedIn: 'root' })
export class ApiConsentPurposeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1ConsentPurposesGetCollection()` */
  static readonly ApiV1ConsentPurposesGetCollectionPath = '/v1/consent_purposes';

  /**
   * Retrieves the collection of ConsentPurpose resources.
   *
   * Retrieves the collection of ConsentPurpose resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentPurposesGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentPurposesGetCollection$Response(params?: ApiV1ConsentPurposesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiConsentPurpose>>> {
    return apiV1ConsentPurposesGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of ConsentPurpose resources.
   *
   * Retrieves the collection of ConsentPurpose resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentPurposesGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentPurposesGetCollection(params?: ApiV1ConsentPurposesGetCollection$Params, context?: HttpContext): Observable<Array<ApiConsentPurpose>> {
    return this.apiV1ConsentPurposesGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiConsentPurpose>>): Array<ApiConsentPurpose> => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentPurposesPost()` */
  static readonly ApiV1ConsentPurposesPostPath = '/v1/consent_purposes';

  /**
   * Creates a ConsentPurpose resource.
   *
   * Creates a ConsentPurpose resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentPurposesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ConsentPurposesPost$Response(params: ApiV1ConsentPurposesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiConsentPurpose>> {
    return apiV1ConsentPurposesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a ConsentPurpose resource.
   *
   * Creates a ConsentPurpose resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentPurposesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ConsentPurposesPost(params: ApiV1ConsentPurposesPost$Params, context?: HttpContext): Observable<ApiConsentPurpose> {
    return this.apiV1ConsentPurposesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiConsentPurpose>): ApiConsentPurpose => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentPurposesUuidGet()` */
  static readonly ApiV1ConsentPurposesUuidGetPath = '/v1/consent_purposes/{uuid}';

  /**
   * Retrieves a ConsentPurpose resource.
   *
   * Retrieves a ConsentPurpose resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentPurposesUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentPurposesUuidGet$Response(params: ApiV1ConsentPurposesUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiConsentPurpose>> {
    return apiV1ConsentPurposesUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a ConsentPurpose resource.
   *
   * Retrieves a ConsentPurpose resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentPurposesUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentPurposesUuidGet(params: ApiV1ConsentPurposesUuidGet$Params, context?: HttpContext): Observable<ApiConsentPurpose> {
    return this.apiV1ConsentPurposesUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiConsentPurpose>): ApiConsentPurpose => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentPurposesUuidPut()` */
  static readonly ApiV1ConsentPurposesUuidPutPath = '/v1/consent_purposes/{uuid}';

  /**
   * Replaces the ConsentPurpose resource.
   *
   * Replaces the ConsentPurpose resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentPurposesUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ConsentPurposesUuidPut$Response(params: ApiV1ConsentPurposesUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiConsentPurpose>> {
    return apiV1ConsentPurposesUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the ConsentPurpose resource.
   *
   * Replaces the ConsentPurpose resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentPurposesUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ConsentPurposesUuidPut(params: ApiV1ConsentPurposesUuidPut$Params, context?: HttpContext): Observable<ApiConsentPurpose> {
    return this.apiV1ConsentPurposesUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiConsentPurpose>): ApiConsentPurpose => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentPurposesUuidDelete()` */
  static readonly ApiV1ConsentPurposesUuidDeletePath = '/v1/consent_purposes/{uuid}';

  /**
   * Removes the ConsentPurpose resource.
   *
   * Removes the ConsentPurpose resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentPurposesUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentPurposesUuidDelete$Response(params: ApiV1ConsentPurposesUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1ConsentPurposesUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the ConsentPurpose resource.
   *
   * Removes the ConsentPurpose resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentPurposesUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentPurposesUuidDelete(params: ApiV1ConsentPurposesUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1ConsentPurposesUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentPurposesUuidPatch()` */
  static readonly ApiV1ConsentPurposesUuidPatchPath = '/v1/consent_purposes/{uuid}';

  /**
   * Updates the ConsentPurpose resource.
   *
   * Updates the ConsentPurpose resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentPurposesUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1ConsentPurposesUuidPatch$Response(params: ApiV1ConsentPurposesUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiConsentPurpose>> {
    return apiV1ConsentPurposesUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the ConsentPurpose resource.
   *
   * Updates the ConsentPurpose resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentPurposesUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1ConsentPurposesUuidPatch(params: ApiV1ConsentPurposesUuidPatch$Params, context?: HttpContext): Observable<ApiConsentPurpose> {
    return this.apiV1ConsentPurposesUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiConsentPurpose>): ApiConsentPurpose => r.body)
    );
  }

}
