/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1CountriesGetCollection } from '../fn/country/api-v-1-countries-get-collection';
import { ApiV1CountriesGetCollection$Params } from '../fn/country/api-v-1-countries-get-collection';
import { apiV1CountriesPost } from '../fn/country/api-v-1-countries-post';
import { ApiV1CountriesPost$Params } from '../fn/country/api-v-1-countries-post';
import { apiV1CountriesUuidDelete } from '../fn/country/api-v-1-countries-uuid-delete';
import { ApiV1CountriesUuidDelete$Params } from '../fn/country/api-v-1-countries-uuid-delete';
import { apiV1CountriesUuidGet } from '../fn/country/api-v-1-countries-uuid-get';
import { ApiV1CountriesUuidGet$Params } from '../fn/country/api-v-1-countries-uuid-get';
import { apiV1CountriesUuidPatch } from '../fn/country/api-v-1-countries-uuid-patch';
import { ApiV1CountriesUuidPatch$Params } from '../fn/country/api-v-1-countries-uuid-patch';
import { apiV1CountriesUuidPut } from '../fn/country/api-v-1-countries-uuid-put';
import { ApiV1CountriesUuidPut$Params } from '../fn/country/api-v-1-countries-uuid-put';
import { ApiCountry } from '../models/api-country';

@Injectable({ providedIn: 'root' })
export class ApiCountryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1CountriesGetCollection()` */
  static readonly ApiV1CountriesGetCollectionPath = '/v1/countries';

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CountriesGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CountriesGetCollection$Response(params?: ApiV1CountriesGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiCountry>>> {
    return apiV1CountriesGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Country resources.
   *
   * Retrieves the collection of Country resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CountriesGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CountriesGetCollection(params?: ApiV1CountriesGetCollection$Params, context?: HttpContext): Observable<Array<ApiCountry>> {
    return this.apiV1CountriesGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiCountry>>): Array<ApiCountry> => r.body)
    );
  }

  /** Path part for operation `apiV1CountriesPost()` */
  static readonly ApiV1CountriesPostPath = '/v1/countries';

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CountriesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CountriesPost$Response(params: ApiV1CountriesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCountry>> {
    return apiV1CountriesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Country resource.
   *
   * Creates a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CountriesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CountriesPost(params: ApiV1CountriesPost$Params, context?: HttpContext): Observable<ApiCountry> {
    return this.apiV1CountriesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCountry>): ApiCountry => r.body)
    );
  }

  /** Path part for operation `apiV1CountriesUuidGet()` */
  static readonly ApiV1CountriesUuidGetPath = '/v1/countries/{uuid}';

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CountriesUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CountriesUuidGet$Response(params: ApiV1CountriesUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCountry>> {
    return apiV1CountriesUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Country resource.
   *
   * Retrieves a Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CountriesUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CountriesUuidGet(params: ApiV1CountriesUuidGet$Params, context?: HttpContext): Observable<ApiCountry> {
    return this.apiV1CountriesUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCountry>): ApiCountry => r.body)
    );
  }

  /** Path part for operation `apiV1CountriesUuidPut()` */
  static readonly ApiV1CountriesUuidPutPath = '/v1/countries/{uuid}';

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CountriesUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CountriesUuidPut$Response(params: ApiV1CountriesUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCountry>> {
    return apiV1CountriesUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Country resource.
   *
   * Replaces the Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CountriesUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CountriesUuidPut(params: ApiV1CountriesUuidPut$Params, context?: HttpContext): Observable<ApiCountry> {
    return this.apiV1CountriesUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCountry>): ApiCountry => r.body)
    );
  }

  /** Path part for operation `apiV1CountriesUuidDelete()` */
  static readonly ApiV1CountriesUuidDeletePath = '/v1/countries/{uuid}';

  /**
   * Removes the Country resource.
   *
   * Removes the Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CountriesUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CountriesUuidDelete$Response(params: ApiV1CountriesUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1CountriesUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Country resource.
   *
   * Removes the Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CountriesUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CountriesUuidDelete(params: ApiV1CountriesUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1CountriesUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1CountriesUuidPatch()` */
  static readonly ApiV1CountriesUuidPatchPath = '/v1/countries/{uuid}';

  /**
   * Updates the Country resource.
   *
   * Updates the Country resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CountriesUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1CountriesUuidPatch$Response(params: ApiV1CountriesUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCountry>> {
    return apiV1CountriesUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Country resource.
   *
   * Updates the Country resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CountriesUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1CountriesUuidPatch(params: ApiV1CountriesUuidPatch$Params, context?: HttpContext): Observable<ApiCountry> {
    return this.apiV1CountriesUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCountry>): ApiCountry => r.body)
    );
  }

}
