/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1CredentialsGetCollection } from '../fn/credential/api-v-1-credentials-get-collection';
import { ApiV1CredentialsGetCollection$Params } from '../fn/credential/api-v-1-credentials-get-collection';
import { apiV1CredentialsIdDelete } from '../fn/credential/api-v-1-credentials-id-delete';
import { ApiV1CredentialsIdDelete$Params } from '../fn/credential/api-v-1-credentials-id-delete';
import { apiV1CredentialsIdGet } from '../fn/credential/api-v-1-credentials-id-get';
import { ApiV1CredentialsIdGet$Params } from '../fn/credential/api-v-1-credentials-id-get';
import { apiV1CredentialsIdPatch } from '../fn/credential/api-v-1-credentials-id-patch';
import { ApiV1CredentialsIdPatch$Params } from '../fn/credential/api-v-1-credentials-id-patch';
import { apiV1CredentialsIdPut } from '../fn/credential/api-v-1-credentials-id-put';
import { ApiV1CredentialsIdPut$Params } from '../fn/credential/api-v-1-credentials-id-put';
import { apiV1CredentialsPost } from '../fn/credential/api-v-1-credentials-post';
import { ApiV1CredentialsPost$Params } from '../fn/credential/api-v-1-credentials-post';
import { ApiCredential } from '../models/api-credential';

@Injectable({ providedIn: 'root' })
export class ApiCredentialService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1CredentialsGetCollection()` */
  static readonly ApiV1CredentialsGetCollectionPath = '/v1/credentials';

  /**
   * Retrieves the collection of Credential resources.
   *
   * Retrieves the collection of Credential resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CredentialsGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CredentialsGetCollection$Response(params?: ApiV1CredentialsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiCredential>>> {
    return apiV1CredentialsGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Credential resources.
   *
   * Retrieves the collection of Credential resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CredentialsGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CredentialsGetCollection(params?: ApiV1CredentialsGetCollection$Params, context?: HttpContext): Observable<Array<ApiCredential>> {
    return this.apiV1CredentialsGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiCredential>>): Array<ApiCredential> => r.body)
    );
  }

  /** Path part for operation `apiV1CredentialsPost()` */
  static readonly ApiV1CredentialsPostPath = '/v1/credentials';

  /**
   * Creates a Credential resource.
   *
   * Creates a Credential resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CredentialsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CredentialsPost$Response(params: ApiV1CredentialsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCredential>> {
    return apiV1CredentialsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Credential resource.
   *
   * Creates a Credential resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CredentialsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CredentialsPost(params: ApiV1CredentialsPost$Params, context?: HttpContext): Observable<ApiCredential> {
    return this.apiV1CredentialsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCredential>): ApiCredential => r.body)
    );
  }

  /** Path part for operation `apiV1CredentialsIdGet()` */
  static readonly ApiV1CredentialsIdGetPath = '/v1/credentials/{id}';

  /**
   * Retrieves a Credential resource.
   *
   * Retrieves a Credential resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CredentialsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CredentialsIdGet$Response(params: ApiV1CredentialsIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCredential>> {
    return apiV1CredentialsIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Credential resource.
   *
   * Retrieves a Credential resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CredentialsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CredentialsIdGet(params: ApiV1CredentialsIdGet$Params, context?: HttpContext): Observable<ApiCredential> {
    return this.apiV1CredentialsIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCredential>): ApiCredential => r.body)
    );
  }

  /** Path part for operation `apiV1CredentialsIdPut()` */
  static readonly ApiV1CredentialsIdPutPath = '/v1/credentials/{id}';

  /**
   * Replaces the Credential resource.
   *
   * Replaces the Credential resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CredentialsIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CredentialsIdPut$Response(params: ApiV1CredentialsIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCredential>> {
    return apiV1CredentialsIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Credential resource.
   *
   * Replaces the Credential resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CredentialsIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1CredentialsIdPut(params: ApiV1CredentialsIdPut$Params, context?: HttpContext): Observable<ApiCredential> {
    return this.apiV1CredentialsIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCredential>): ApiCredential => r.body)
    );
  }

  /** Path part for operation `apiV1CredentialsIdDelete()` */
  static readonly ApiV1CredentialsIdDeletePath = '/v1/credentials/{id}';

  /**
   * Removes the Credential resource.
   *
   * Removes the Credential resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CredentialsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CredentialsIdDelete$Response(params: ApiV1CredentialsIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1CredentialsIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Credential resource.
   *
   * Removes the Credential resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CredentialsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1CredentialsIdDelete(params: ApiV1CredentialsIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1CredentialsIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1CredentialsIdPatch()` */
  static readonly ApiV1CredentialsIdPatchPath = '/v1/credentials/{id}';

  /**
   * Updates the Credential resource.
   *
   * Updates the Credential resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1CredentialsIdPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1CredentialsIdPatch$Response(params: ApiV1CredentialsIdPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiCredential>> {
    return apiV1CredentialsIdPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Credential resource.
   *
   * Updates the Credential resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1CredentialsIdPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1CredentialsIdPatch(params: ApiV1CredentialsIdPatch$Params, context?: HttpContext): Observable<ApiCredential> {
    return this.apiV1CredentialsIdPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiCredential>): ApiCredential => r.body)
    );
  }

}
