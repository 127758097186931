/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApiMembership } from '../../models/api-membership';

export interface ApiV1MembershipsGetCollection$Params {

/**
 * The collection page number
 */
  page?: number;
  'category.season.label'?: string;
  'category.season.label[]'?: Array<string>;
  'member.uuid'?: string;
  'member.uuid[]'?: Array<string>;
  'attendanceRate[between]'?: string;
  'attendanceRate[gt]'?: string;
  'attendanceRate[gte]'?: string;
  'attendanceRate[lt]'?: string;
  'attendanceRate[lte]'?: string;

/**
 * Transaction ID for the business request
 */
  'X-Transaction-Id'?: string;
}

export function apiV1MembershipsGetCollection(http: HttpClient, rootUrl: string, params?: ApiV1MembershipsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiMembership>>> {
  const rb = new RequestBuilder(rootUrl, apiV1MembershipsGetCollection.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {"style":"form","explode":false});
    rb.query('category.season.label', params['category.season.label'], {"style":"form","explode":false});
    rb.query('category.season.label[]', params['category.season.label[]'], {"style":"form","explode":true});
    rb.query('member.uuid', params['member.uuid'], {"style":"form","explode":false});
    rb.query('member.uuid[]', params['member.uuid[]'], {"style":"form","explode":true});
    rb.query('attendanceRate[between]', params['attendanceRate[between]'], {"style":"form","explode":false});
    rb.query('attendanceRate[gt]', params['attendanceRate[gt]'], {"style":"form","explode":false});
    rb.query('attendanceRate[gte]', params['attendanceRate[gte]'], {"style":"form","explode":false});
    rb.query('attendanceRate[lt]', params['attendanceRate[lt]'], {"style":"form","explode":false});
    rb.query('attendanceRate[lte]', params['attendanceRate[lte]'], {"style":"form","explode":false});
    rb.header('X-Transaction-Id', params['X-Transaction-Id'], {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ApiMembership>>;
    })
  );
}

apiV1MembershipsGetCollection.PATH = '/v1/memberships';
