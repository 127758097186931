/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { ApiExternalData } from '../models/api-external-data';
export class ApiSeason {
  categories?: Array<string>;
  createdAt?: string;
  endDate?: string;
  externalDatas?: Array<ApiExternalData>;
  id?: number;
  joinEndDate?: string;
  joinStartDate?: string;
  label?: string;
  startDate?: string;
  updatedAt?: string;
  uuid?: string;
}

