/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiExternalData {
  category?: (string | null);
  clubMember?: (string | null);
  country?: (string | null);
  dataProvider?: string;
  dataType?: string;
  externalLabel?: string;
  externalReference?: string;
  externalSubresourceReference?: (string | null);
  id?: number;
  season?: (string | null);
}

