/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiJoinPeriod {
  category?: string;
  createdAt?: string;
  endDate?: string;
  id?: number;
  joinRule?: (string | null);
  startDate?: string;
  updatedAt?: string;
  uuid?: string;
}

