/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiConsent {
  acceptDate?: (string | null);
  consentPurpose?: string;
  createdAt?: string;
  id?: number;
  member?: string;
  rejectDate?: (string | null);
  updatedAt?: string;
  uuid?: string;
}

