/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiMembership {
  attendanceRate?: (number | null);
  category?: string;
  createdAt?: string;
  id?: number;
  member?: string;
  role?: string;
  updatedAt?: string;
  uuid?: string;
}

