/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1HouseholdsGetCollection } from '../fn/household/api-v-1-households-get-collection';
import { ApiV1HouseholdsGetCollection$Params } from '../fn/household/api-v-1-households-get-collection';
import { apiV1HouseholdsPost } from '../fn/household/api-v-1-households-post';
import { ApiV1HouseholdsPost$Params } from '../fn/household/api-v-1-households-post';
import { apiV1HouseholdsUuidDelete } from '../fn/household/api-v-1-households-uuid-delete';
import { ApiV1HouseholdsUuidDelete$Params } from '../fn/household/api-v-1-households-uuid-delete';
import { apiV1HouseholdsUuidGet } from '../fn/household/api-v-1-households-uuid-get';
import { ApiV1HouseholdsUuidGet$Params } from '../fn/household/api-v-1-households-uuid-get';
import { apiV1HouseholdsUuidPatch } from '../fn/household/api-v-1-households-uuid-patch';
import { ApiV1HouseholdsUuidPatch$Params } from '../fn/household/api-v-1-households-uuid-patch';
import { apiV1HouseholdsUuidPut } from '../fn/household/api-v-1-households-uuid-put';
import { ApiV1HouseholdsUuidPut$Params } from '../fn/household/api-v-1-households-uuid-put';
import { ApiHousehold } from '../models/api-household';

@Injectable({ providedIn: 'root' })
export class ApiHouseholdService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1HouseholdsGetCollection()` */
  static readonly ApiV1HouseholdsGetCollectionPath = '/v1/households';

  /**
   * Retrieves the collection of Household resources.
   *
   * Retrieves the collection of Household resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1HouseholdsGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1HouseholdsGetCollection$Response(params?: ApiV1HouseholdsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiHousehold>>> {
    return apiV1HouseholdsGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Household resources.
   *
   * Retrieves the collection of Household resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1HouseholdsGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1HouseholdsGetCollection(params?: ApiV1HouseholdsGetCollection$Params, context?: HttpContext): Observable<Array<ApiHousehold>> {
    return this.apiV1HouseholdsGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiHousehold>>): Array<ApiHousehold> => r.body)
    );
  }

  /** Path part for operation `apiV1HouseholdsPost()` */
  static readonly ApiV1HouseholdsPostPath = '/v1/households';

  /**
   * Creates a Household resource.
   *
   * Creates a Household resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1HouseholdsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1HouseholdsPost$Response(params: ApiV1HouseholdsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHousehold>> {
    return apiV1HouseholdsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Household resource.
   *
   * Creates a Household resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1HouseholdsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1HouseholdsPost(params: ApiV1HouseholdsPost$Params, context?: HttpContext): Observable<ApiHousehold> {
    return this.apiV1HouseholdsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHousehold>): ApiHousehold => r.body)
    );
  }

  /** Path part for operation `apiV1HouseholdsUuidGet()` */
  static readonly ApiV1HouseholdsUuidGetPath = '/v1/households/{uuid}';

  /**
   * Retrieves a Household resource.
   *
   * Retrieves a Household resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1HouseholdsUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1HouseholdsUuidGet$Response(params: ApiV1HouseholdsUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHousehold>> {
    return apiV1HouseholdsUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Household resource.
   *
   * Retrieves a Household resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1HouseholdsUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1HouseholdsUuidGet(params: ApiV1HouseholdsUuidGet$Params, context?: HttpContext): Observable<ApiHousehold> {
    return this.apiV1HouseholdsUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHousehold>): ApiHousehold => r.body)
    );
  }

  /** Path part for operation `apiV1HouseholdsUuidPut()` */
  static readonly ApiV1HouseholdsUuidPutPath = '/v1/households/{uuid}';

  /**
   * Replaces the Household resource.
   *
   * Replaces the Household resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1HouseholdsUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1HouseholdsUuidPut$Response(params: ApiV1HouseholdsUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHousehold>> {
    return apiV1HouseholdsUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Household resource.
   *
   * Replaces the Household resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1HouseholdsUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1HouseholdsUuidPut(params: ApiV1HouseholdsUuidPut$Params, context?: HttpContext): Observable<ApiHousehold> {
    return this.apiV1HouseholdsUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHousehold>): ApiHousehold => r.body)
    );
  }

  /** Path part for operation `apiV1HouseholdsUuidDelete()` */
  static readonly ApiV1HouseholdsUuidDeletePath = '/v1/households/{uuid}';

  /**
   * Removes the Household resource.
   *
   * Removes the Household resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1HouseholdsUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1HouseholdsUuidDelete$Response(params: ApiV1HouseholdsUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1HouseholdsUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Household resource.
   *
   * Removes the Household resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1HouseholdsUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1HouseholdsUuidDelete(params: ApiV1HouseholdsUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1HouseholdsUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1HouseholdsUuidPatch()` */
  static readonly ApiV1HouseholdsUuidPatchPath = '/v1/households/{uuid}';

  /**
   * Updates the Household resource.
   *
   * Updates the Household resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1HouseholdsUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1HouseholdsUuidPatch$Response(params: ApiV1HouseholdsUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiHousehold>> {
    return apiV1HouseholdsUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Household resource.
   *
   * Updates the Household resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1HouseholdsUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1HouseholdsUuidPatch(params: ApiV1HouseholdsUuidPatch$Params, context?: HttpContext): Observable<ApiHousehold> {
    return this.apiV1HouseholdsUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiHousehold>): ApiHousehold => r.body)
    );
  }

}
