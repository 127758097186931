/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiVenue {
  address?: string;
  createdAt?: string;
  id?: number;
  name?: string;
  shortName?: string;
  town?: string;
  updatedAt?: string;
  uuid?: string;
  zip?: string;
}

