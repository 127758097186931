/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiConsentPurpose {
  createdAt?: string;
  description?: string;
  displayOrder?: (number | null);
  id?: number;
  updatedAt?: string;
  uuid?: string;
}

