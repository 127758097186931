/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1SeasonsGetCollection } from '../fn/season/api-v-1-seasons-get-collection';
import { ApiV1SeasonsGetCollection$Params } from '../fn/season/api-v-1-seasons-get-collection';
import { apiV1SeasonsIdDelete } from '../fn/season/api-v-1-seasons-id-delete';
import { ApiV1SeasonsIdDelete$Params } from '../fn/season/api-v-1-seasons-id-delete';
import { apiV1SeasonsIdGet } from '../fn/season/api-v-1-seasons-id-get';
import { ApiV1SeasonsIdGet$Params } from '../fn/season/api-v-1-seasons-id-get';
import { apiV1SeasonsIdPatch } from '../fn/season/api-v-1-seasons-id-patch';
import { ApiV1SeasonsIdPatch$Params } from '../fn/season/api-v-1-seasons-id-patch';
import { apiV1SeasonsIdPut } from '../fn/season/api-v-1-seasons-id-put';
import { ApiV1SeasonsIdPut$Params } from '../fn/season/api-v-1-seasons-id-put';
import { apiV1SeasonsPost } from '../fn/season/api-v-1-seasons-post';
import { ApiV1SeasonsPost$Params } from '../fn/season/api-v-1-seasons-post';
import { ApiSeason } from '../models/api-season';

@Injectable({ providedIn: 'root' })
export class ApiSeasonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1SeasonsGetCollection()` */
  static readonly ApiV1SeasonsGetCollectionPath = '/v1/seasons';

  /**
   * Retrieves the collection of Season resources.
   *
   * Retrieves the collection of Season resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SeasonsGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SeasonsGetCollection$Response(params?: ApiV1SeasonsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiSeason>>> {
    return apiV1SeasonsGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Season resources.
   *
   * Retrieves the collection of Season resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1SeasonsGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SeasonsGetCollection(params?: ApiV1SeasonsGetCollection$Params, context?: HttpContext): Observable<Array<ApiSeason>> {
    return this.apiV1SeasonsGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiSeason>>): Array<ApiSeason> => r.body)
    );
  }

  /** Path part for operation `apiV1SeasonsPost()` */
  static readonly ApiV1SeasonsPostPath = '/v1/seasons';

  /**
   * Creates a Season resource.
   *
   * Creates a Season resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SeasonsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1SeasonsPost$Response(params: ApiV1SeasonsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiSeason>> {
    return apiV1SeasonsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Season resource.
   *
   * Creates a Season resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1SeasonsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1SeasonsPost(params: ApiV1SeasonsPost$Params, context?: HttpContext): Observable<ApiSeason> {
    return this.apiV1SeasonsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiSeason>): ApiSeason => r.body)
    );
  }

  /** Path part for operation `apiV1SeasonsIdGet()` */
  static readonly ApiV1SeasonsIdGetPath = '/v1/seasons/{id}';

  /**
   * Retrieves a Season resource.
   *
   * Retrieves a Season resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SeasonsIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SeasonsIdGet$Response(params: ApiV1SeasonsIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiSeason>> {
    return apiV1SeasonsIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Season resource.
   *
   * Retrieves a Season resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1SeasonsIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SeasonsIdGet(params: ApiV1SeasonsIdGet$Params, context?: HttpContext): Observable<ApiSeason> {
    return this.apiV1SeasonsIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiSeason>): ApiSeason => r.body)
    );
  }

  /** Path part for operation `apiV1SeasonsIdPut()` */
  static readonly ApiV1SeasonsIdPutPath = '/v1/seasons/{id}';

  /**
   * Replaces the Season resource.
   *
   * Replaces the Season resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SeasonsIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1SeasonsIdPut$Response(params: ApiV1SeasonsIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiSeason>> {
    return apiV1SeasonsIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Season resource.
   *
   * Replaces the Season resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1SeasonsIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1SeasonsIdPut(params: ApiV1SeasonsIdPut$Params, context?: HttpContext): Observable<ApiSeason> {
    return this.apiV1SeasonsIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiSeason>): ApiSeason => r.body)
    );
  }

  /** Path part for operation `apiV1SeasonsIdDelete()` */
  static readonly ApiV1SeasonsIdDeletePath = '/v1/seasons/{id}';

  /**
   * Removes the Season resource.
   *
   * Removes the Season resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SeasonsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SeasonsIdDelete$Response(params: ApiV1SeasonsIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1SeasonsIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Season resource.
   *
   * Removes the Season resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1SeasonsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SeasonsIdDelete(params: ApiV1SeasonsIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1SeasonsIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1SeasonsIdPatch()` */
  static readonly ApiV1SeasonsIdPatchPath = '/v1/seasons/{id}';

  /**
   * Updates the Season resource.
   *
   * Updates the Season resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SeasonsIdPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1SeasonsIdPatch$Response(params: ApiV1SeasonsIdPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiSeason>> {
    return apiV1SeasonsIdPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Season resource.
   *
   * Updates the Season resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1SeasonsIdPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1SeasonsIdPatch(params: ApiV1SeasonsIdPatch$Params, context?: HttpContext): Observable<ApiSeason> {
    return this.apiV1SeasonsIdPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiSeason>): ApiSeason => r.body)
    );
  }

}
