/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiV1ConsentsGetCollection } from '../fn/consent/api-v-1-consents-get-collection';
import { ApiV1ConsentsGetCollection$Params } from '../fn/consent/api-v-1-consents-get-collection';
import { apiV1ConsentsPost } from '../fn/consent/api-v-1-consents-post';
import { ApiV1ConsentsPost$Params } from '../fn/consent/api-v-1-consents-post';
import { apiV1ConsentsUuidDelete } from '../fn/consent/api-v-1-consents-uuid-delete';
import { ApiV1ConsentsUuidDelete$Params } from '../fn/consent/api-v-1-consents-uuid-delete';
import { apiV1ConsentsUuidGet } from '../fn/consent/api-v-1-consents-uuid-get';
import { ApiV1ConsentsUuidGet$Params } from '../fn/consent/api-v-1-consents-uuid-get';
import { apiV1ConsentsUuidPatch } from '../fn/consent/api-v-1-consents-uuid-patch';
import { ApiV1ConsentsUuidPatch$Params } from '../fn/consent/api-v-1-consents-uuid-patch';
import { apiV1ConsentsUuidPut } from '../fn/consent/api-v-1-consents-uuid-put';
import { ApiV1ConsentsUuidPut$Params } from '../fn/consent/api-v-1-consents-uuid-put';
import { ApiConsent } from '../models/api-consent';

@Injectable({ providedIn: 'root' })
export class ApiConsentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiV1ConsentsGetCollection()` */
  static readonly ApiV1ConsentsGetCollectionPath = '/v1/consents';

  /**
   * Retrieves the collection of Consent resources.
   *
   * Retrieves the collection of Consent resources.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentsGetCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentsGetCollection$Response(params?: ApiV1ConsentsGetCollection$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ApiConsent>>> {
    return apiV1ConsentsGetCollection(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the collection of Consent resources.
   *
   * Retrieves the collection of Consent resources.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentsGetCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentsGetCollection(params?: ApiV1ConsentsGetCollection$Params, context?: HttpContext): Observable<Array<ApiConsent>> {
    return this.apiV1ConsentsGetCollection$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApiConsent>>): Array<ApiConsent> => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentsPost()` */
  static readonly ApiV1ConsentsPostPath = '/v1/consents';

  /**
   * Creates a Consent resource.
   *
   * Creates a Consent resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ConsentsPost$Response(params: ApiV1ConsentsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiConsent>> {
    return apiV1ConsentsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a Consent resource.
   *
   * Creates a Consent resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ConsentsPost(params: ApiV1ConsentsPost$Params, context?: HttpContext): Observable<ApiConsent> {
    return this.apiV1ConsentsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiConsent>): ApiConsent => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentsUuidGet()` */
  static readonly ApiV1ConsentsUuidGetPath = '/v1/consents/{uuid}';

  /**
   * Retrieves a Consent resource.
   *
   * Retrieves a Consent resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentsUuidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentsUuidGet$Response(params: ApiV1ConsentsUuidGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiConsent>> {
    return apiV1ConsentsUuidGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a Consent resource.
   *
   * Retrieves a Consent resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentsUuidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentsUuidGet(params: ApiV1ConsentsUuidGet$Params, context?: HttpContext): Observable<ApiConsent> {
    return this.apiV1ConsentsUuidGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiConsent>): ApiConsent => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentsUuidPut()` */
  static readonly ApiV1ConsentsUuidPutPath = '/v1/consents/{uuid}';

  /**
   * Replaces the Consent resource.
   *
   * Replaces the Consent resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentsUuidPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ConsentsUuidPut$Response(params: ApiV1ConsentsUuidPut$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiConsent>> {
    return apiV1ConsentsUuidPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Replaces the Consent resource.
   *
   * Replaces the Consent resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentsUuidPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ConsentsUuidPut(params: ApiV1ConsentsUuidPut$Params, context?: HttpContext): Observable<ApiConsent> {
    return this.apiV1ConsentsUuidPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiConsent>): ApiConsent => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentsUuidDelete()` */
  static readonly ApiV1ConsentsUuidDeletePath = '/v1/consents/{uuid}';

  /**
   * Removes the Consent resource.
   *
   * Removes the Consent resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentsUuidDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentsUuidDelete$Response(params: ApiV1ConsentsUuidDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiV1ConsentsUuidDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes the Consent resource.
   *
   * Removes the Consent resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentsUuidDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ConsentsUuidDelete(params: ApiV1ConsentsUuidDelete$Params, context?: HttpContext): Observable<void> {
    return this.apiV1ConsentsUuidDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiV1ConsentsUuidPatch()` */
  static readonly ApiV1ConsentsUuidPatchPath = '/v1/consents/{uuid}';

  /**
   * Updates the Consent resource.
   *
   * Updates the Consent resource.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ConsentsUuidPatch()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1ConsentsUuidPatch$Response(params: ApiV1ConsentsUuidPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiConsent>> {
    return apiV1ConsentsUuidPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the Consent resource.
   *
   * Updates the Consent resource.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiV1ConsentsUuidPatch$Response()` instead.
   *
   * This method sends `application/merge-patch+json` and handles request body of type `application/merge-patch+json`.
   */
  apiV1ConsentsUuidPatch(params: ApiV1ConsentsUuidPatch$Params, context?: HttpContext): Observable<ApiConsent> {
    return this.apiV1ConsentsUuidPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiConsent>): ApiConsent => r.body)
    );
  }

}
