/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export class ApiDuesRate {
  createdAt?: string;
  id?: number;
  inScopeTown?: boolean;
  ranking?: number;
  rate?: number;
  updatedAt?: string;
  uuid?: string;
}

